import { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import useIsInViewport from 'shared/hooks/useIsInViewport';
import useTranslation from 'shared/hooks/useTranslation';
import { useInView, animated } from '@react-spring/web';

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  font-family: ${({ theme }) => theme.FONTS.MAIN};

  ${({ theme }) => theme.MEDIA.XXL} {
    padding: 8rem 0;
    height: auto;
    box-sizing: border-box;
  }
`;

const gradient = keyframes`
  {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 2.4rem 0 2.4rem;

  ${({ theme }) => theme.MEDIA.XL} {
    padding: 8rem 2.4rem 0 2.4rem;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    padding: 12rem 6.4rem 0 6.4rem;
  }
`;

const RegularHeader = styled(animated.h2)`
  font-size: 3.4rem;
  font-weight: 600;
  font-family: inherit;
  line-height: 4.2rem;
  text-align: center;
  color: ${({ theme }) => theme.COLORS.V2.PRIMARY};

  ${({ theme }) => theme.MEDIA.XL} {
    font-size: 8.4rem;
    line-height: 8.4rem;
  }
`;

const AnimatedGradientHeader = styled(RegularHeader)`
  animation: ${gradient} 5s ease-in-out infinite;
  background: linear-gradient(263.37deg, #fccc51 0.21%, #fd6e40 52.03%, #e75b95 103.85%);
  background-size: 150%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 150%;
`;

const Video = styled(animated.div)<VideoProps>`
  height: auto;
  width: 100%;
  max-width: 192rem;
  margin: 0 auto;
  display: ${({ isDisplay }) => (isDisplay ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  video {
    height: auto;
    width: 100%;
  }
`;

type VideoProps = {
  isDisplay: boolean;
};
const VIDEO_URL = 'https://cdn.trustmate.io/video/:lang/animation-home-page-google-light';

function AnimationSection(): JSX.Element {
  const { t, lang } = useTranslation('homePage');
  const [status, setStatus] = useState(false);

  const threshold = { threshold: 0 };
  const videoRef = useRef(null);
  const isVideoInView = useIsInViewport(videoRef, threshold);

  useEffect(() => {
    if (isVideoInView && !status) {
      setStatus(true);
    }
  }, [isVideoInView, status]);

  const [regHeader, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      config: {
        duration: 800,
      },
    }),
    {
      rootMargin: '-10% 0%',
    },
  );

  const [header, springs2] = useInView(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
      delay: 800,
      config: {
        duration: 800,
      },
    }),
    {
      rootMargin: '-20% 0%',
    },
  );

  function returnSource(videoFormat = 'webm') {
    if (lang === 'pl') {
      return <source src={`${VIDEO_URL.replace(':lang/', '')}.${videoFormat}`} type={`video/${videoFormat}`} />;
    }
    if (lang === 'cs' || lang === 'sk') {
      return <source src={`${VIDEO_URL.replace(':lang', lang)}.${videoFormat}`} type={`video/${videoFormat}`} />;
    }

    return <source src={`${VIDEO_URL.replace(':lang', 'en')}.${videoFormat}`} type={`video/${videoFormat}`} />;
  }

  return (
    <Section>
      <HeaderWrapper ref={videoRef}>
        <RegularHeader ref={regHeader} style={springs}>
          {t('googleSection.reviewersIsYours')}
        </RegularHeader>
        <AnimatedGradientHeader ref={header} style={springs2}>
          {t('googleSection.marketingDepartment')}.
        </AnimatedGradientHeader>
      </HeaderWrapper>
      <Video isDisplay={status}>
        <video autoPlay loop muted poster="/img/home/v2/animation-google-search.png" playsInline>
          {status ? returnSource('webm') : null}
          {status ? returnSource('mp4') : null}
        </video>
      </Video>
    </Section>
  );
}

export default AnimationSection;
